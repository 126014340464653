.pay-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.pay-success-icon {
    width: 100px;
    height: 100px;
    background-color: #4CAF50;
    border-radius: 50%;
}

.pay-success-text {
    margin-top: 20px;
    text-align: center;
}

.pay-success-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pay-success-button button {
    margin: 0 10px;
    background-color: #a86bf9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.pay-success-button button:hover {
    background-color: #4CAF50;
    color: white;
}

