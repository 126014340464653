.book-management-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.book-management-content {
    background-color: white;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    max-height: 80vh;
    padding: 20px;
    position: relative;
}

.book-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.book-management-header h2 {
    margin: 0;
    font-size: 1.5rem;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 5px;
}

.book-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    overflow-y: auto;
    max-height: calc(80vh - 100px);
    padding: 10px;
}

.book-item {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 10px;
    transition: transform 0.2s;
}

.book-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.book-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
}

.book-info {
    margin-top: 10px;
}

.book-info h3 {
    margin: 0;
    font-size: 1rem;
}

.book-info p {
    margin: 5px 0 0;
    color: #666;
    font-size: 0.9rem;
}

.loading {
    text-align: center;
    padding: 20px;
    color: #666;
}

.download-controls {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.download-progress {
    width: 100%;
    height: 4px;
    background-color: #eee;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
}

.progress-bar {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.3s ease;
}

.progress-text {
    font-size: 12px;
    color: #666;
    text-align: center;
    margin-top: 4px;
}

.download-button, .cancel-button {
    width: 100%;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.download-button {
    background-color: #4CAF50;
    color: white;
}

.download-button:hover {
    background-color: #45a049;
}

.download-button:active {
    background-color: #3d8b40;
}

.cancel-button {
    background-color: #ff4444;
    color: white;
}

.cancel-button:hover {
    background-color: #ff3333;
}

.cancel-button:active {
    background-color: #cc0000;
}

.download-button:disabled, .cancel-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    opacity: 0.7;
}