.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.teacher-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.teacher-info {
  flex: 1;
  text-align: left;
  max-width: 400px;
}

.teacher-info h2 {
  margin-bottom: 15px;
}

.teacher-avatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  flex-shrink: 0;
}

.introduction {
  margin: 15px 0;
  text-align: left;
  line-height: 1.6;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-height: 150px;
  overflow-y: auto;
  padding-right: 10px;
}