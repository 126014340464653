.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: center;
}

.card h2 {
    margin-bottom: 20px;
}

.formGroup {
    margin-bottom: 20px;
    text-align: left;
}

.formGroup label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: bold;
}

.formGroup input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button:hover {
    background-color: #0056b3;
}

.cancelButton {
    padding: 10px 20px;
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.cancelButton:hover {
    background-color: #e0e0e0;
}

.errorMessage {
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
}

.amounts {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.amountButton {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    transition: all 0.3s;
    color: #333;
}

.amountButton:hover {
    background: #f0f0f0;
}

.amountButton.selected {
    background: #007bff;
    color: white;
    border-color: #0056b3;
}

.bonusTime {
    margin: 15px 0;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
    color: #28a745;
    font-weight: bold;
}