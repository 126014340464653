.language-switcher {
  display: flex;
  align-items: center;
}

.language-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.language-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.language-button.active {
  background-color: #1890ff;
  color: white;
  border-color: #1890ff;
} 