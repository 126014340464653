.connection-prep {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f5f5f5;
}

.connection-status {
    background: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    text-align: center;
}

.connection-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 15px 0;
}

.success-icon {
    color: #4caf50;
    font-size: 24px;
}

.error-icon {
    color: #f44336;
    font-size: 24px;
}

.loading-spinner {
    width: 24px;
    height: 24px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.action-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}

.video-preview {
    width: 100%;
    max-width: 400px;
    height: auto;
    aspect-ratio: 4/3;
    background: #000;
    margin: 20px auto;
    border-radius: 12px;
    object-fit: cover;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.control-buttons {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    flex-direction: row;
}

.control-button {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.control-button.active {
    background-color: #4caf50;
    color: white;
}

.control-button.inactive {
    background-color: #f44336;
    color: white;
}

.control-button:hover {
    transform: scale(1.1);
}

.start-button {
    width: 60px;
    height: 60px;
    background-color: #2196f3;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.start-button:hover {
    background-color: #1976d2;
    transform: scale(1.1);
}

.action-buttons button {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    background-color: #2196f3;
    color: white;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.action-buttons button:hover {
    background-color: #1976d2;
    transform: scale(1.1);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* 添加媒体查询以适配移动端 */
@media (max-width: 768px) {
    .connection-status {
        padding: 15px;
        margin: 10px;
    }

    .video-preview {
        width: 100%;
        max-width: 100%;
        height: 240px;
        aspect-ratio: auto;
        object-fit: cover;
    }

    .control-buttons {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }

    .control-button {
        flex: 0 1 auto;
        padding: 12px;
        min-width: 120px;
    }

    .controls-container {
        padding: 0 10px;
    }

    .control-buttons {
        gap: 10px;
    }
}

/* 小屏幕设备的额外优化 */
@media (max-width: 480px) {
    .video-preview {
        height: 200px;
    }
}

.connection-status-container {
    margin: 20px 0;
}

/* 新增控制区域容器 */
.controls-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    margin: 20px 0;
} 