.order-list-container {
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    margin: 20px;
}

.order-list-container h2 {
    color: #1a1a1a;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
}

.table-container {
    overflow-x: auto;
}

.order-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background: #fff;
}

.order-table th {
    background: #f5f7fa;
    padding: 16px;
    text-align: left;
    color: #606266;
    font-weight: 500;
    border-bottom: 1px solid #ebeef5;
}

.order-table td {
    padding: 16px;
    border-bottom: 1px solid #ebeef5;
    color: #606266;
}

.order-table tbody tr:hover {
    background-color: #f5f7fa;
}

/* 状态标签样式 */
.status-badge {
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 13px;
    display: inline-block;
}

.status-pending {
    background-color: #e6a23c20;
    color: #e6a23c;
}

.status-success {
    background-color: #67c23a20;
    color: #67c23a;
}

.status-failed {
    background-color: #f56c6c20;
    color: #f56c6c;
}

.status-unknown {
    background-color: #90909020;
    color: #909090;
}

/* 响应式设计 */
@media screen and (max-width: 768px) {
    .order-list-container {
        margin: 10px;
        padding: 16px;
    }
    
    .order-table th,
    .order-table td {
        padding: 12px 8px;
        font-size: 14px;
    }
}

.page-container {
    padding-top: 60px; /* 根据你的导航栏高度调整 */
}

.refresh-button {
    padding: 4px 12px;
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.refresh-button:hover {
    background-color: #40a9ff;
}
