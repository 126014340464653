.appointment-container {
    padding: 20px;
    max-width: 1000px;
    margin: 80px auto 0; /* 增加上边距，与导航栏分开 */
}

.appointment-title {
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
}

.appointments-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.appointment-card {
    background-color: white;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.appointment-card:hover {
    transform: translateX(5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.appointment-info-group {
    display: flex;
    gap: 2rem;
    flex: 1;
}

.appointment-info {
    margin: 0;
    color: #666;
    font-size: 1rem;
    min-width: 150px;
}

.appointment-info strong {
    color: #333;
    margin-right: 0.5rem;
    display: block;
    margin-bottom: 0.2rem;
}

.appointment-status {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    min-width: 100px;
    margin-left: 1rem;
}

.status-booking {
    background-color: #fff3cd;
    color: #856404;
}

.status-confirmed {
    background-color: #d4edda;
    color: #155724;
}

.status-cancelled {
    background-color: #f8d7da;
    color: #721c24;
}

.appointment-card.clickable {
    cursor: pointer;
    position: relative;
}

.appointment-card.clickable:after {
    content: '→';
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.2s ease;
}

.appointment-card.clickable:hover:after {
    opacity: 1;
}

.appointment-card.clickable:hover {
    background-color: #f8f9fa;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
    .appointment-container {
        padding: 1rem;
        margin-top: 60px;
    }

    .appointment-title {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    .appointment-card {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
    }

    .appointment-info-group {
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
    }

    .appointment-info {
        min-width: unset;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
    }

    .appointment-info strong {
        display: inline-block;
        width: 100px;
        margin-bottom: 0;
    }

    .appointment-status {
        margin: 1rem 0 0 0;
        width: 100%;
        min-width: unset;
    }

    .appointment-card.clickable:after {
        top: unset;
        bottom: 1rem;
        right: 1rem;
    }

    .appointment-card:hover {
        transform: translateX(0);
    }
}

/* 超小屏幕适配 */
@media screen and (max-width: 480px) {
    .appointment-container {
        padding: 0.8rem;
        margin-top: 50px;
    }

    .appointment-title {
        font-size: 1.8rem;
    }

    .appointment-info {
        font-size: 0.85rem;
    }

    .appointment-info strong {
        width: 90px;
    }
}

.loading-container {
    padding: 20px;
    text-align: center;
}

.loading-spinner {
    color: #666;
    font-size: 14px;
}

.no-more-data {
    color: #999;
    font-size: 14px;
    padding: 20px;
    text-align: center;
}

