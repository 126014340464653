.seller-login-page {
    display: flex;
    min-height: 100vh;
    background-color: #fff;
    justify-content: center;
    align-items: center;
}

.seller-login-form {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: clamp(1.5rem, 4vw, 3rem);
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
}

/* 创建一个包装器来控制内容宽度 */
.seller-login-content {
    width: 100%;
    max-width: 280px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.seller-login-title {
    font-size: clamp(20px, 4vw, 24px);
    font-weight: bold;
    margin-bottom: clamp(20px, 4vh, 30px);
    text-align: center;
    width: 100%;
}

.seller-login-input {
    background-color: #f5f5f5;
    padding: clamp(12px, 2vh, 15px);
    border-radius: 8px;
    margin-bottom: clamp(12px, 2vh, 15px);
    font-size: clamp(14px, 2vw, 16px);
    border: none;
    outline: none;
    width: 100%;
    box-sizing: border-box;
}

.seller-login-submit-btn {
    background-color: #22c55e;
    color: #fff;
    padding: clamp(12px, 2vh, 15px);
    border-radius: 8px;
    border: none;
    font-size: clamp(14px, 2vw, 16px);
    font-weight: 600;
    cursor: pointer;
    margin-top: clamp(5px, 1vh, 10px);
    width: 100%;
    transition: background-color 0.2s;
}

.seller-login-submit-btn:hover {
    background-color: #1ea952;
}

.seller-login-register-link {
    margin-top: clamp(15px, 3vh, 20px);
    color: #22c55e;
    font-size: clamp(14px, 2vw, 16px);
    background: none;
    border: none;
    cursor: pointer;
    padding: clamp(8px, 1.5vh, 10px);
    width: 100%;
    text-align: center;
}

.seller-login-register-link:hover {
    opacity: 0.8;
}

/* 确保所有子元素都使用 border-box */
* {
    box-sizing: border-box;
}

/* 平板断点 */
@media (max-width: 768px) {
    .seller-login-form {
        max-width: 100%;
        padding: 2rem;
    }
}

/* 手机断点 */
@media (max-width: 480px) {
    .seller-login-page {
        align-items: flex-start;
        padding-top: clamp(2rem, 5vh, 4rem);
    }

    .seller-login-form {
        padding: clamp(1rem, 3vw, 1.5rem);
    }
}

/* 小屏手机断点 */
@media (max-width: 320px) {
    .seller-login-form {
        padding: 1rem;
    }
}

.seller-login-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.seller-login-title {
    margin: 0;
    /* 移除标题默认的 margin */
}