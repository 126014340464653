.confirm-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  backdrop-filter: blur(4px);
  animation: fadeIn 0.2s ease-out;
}

.confirm-dialog {
  background: rgba(255, 255, 255, 0.95);
  padding: 24px 32px;
  border-radius: 16px;
  min-width: 320px;
  max-width: 90%;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  transform: translateY(0);
  animation: slideUp 0.3s ease-out;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.confirm-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.confirm-dialog-buttons button {
  padding: 10px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.2s ease;
  border: none;
}

.confirm-dialog-buttons button:first-child {
  background: rgba(0, 0, 0, 0.05);
  color: #666;
}

.confirm-dialog-buttons button:last-child {
  background: #007AFF;
  color: white;
}

.confirm-dialog-buttons button:first-child:hover {
  background: rgba(0, 0, 0, 0.1);
}

.confirm-dialog-buttons button:last-child:hover {
  background: #0066DD;
  transform: translateY(-1px);
}

.confirm-dialog-buttons button:active {
  transform: translateY(1px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .confirm-dialog {
    padding: 20px 24px;
    min-width: 280px;
  }

  .confirm-dialog-buttons button {
    padding: 8px 20px;
    font-size: 14px;
  }
}

@media (prefers-color-scheme: dark) {
  .confirm-dialog {
    background: rgba(30, 30, 30, 0.95);
    color: #fff;
    border-color: rgba(255, 255, 255, 0.1);
  }

  .confirm-dialog-buttons button:first-child {
    background: rgba(255, 255, 255, 0.1);
    color: #eee;
  }

  .confirm-dialog-buttons button:first-child:hover {
    background: rgba(255, 255, 255, 0.15);
  }
} 