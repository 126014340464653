.student-connection-prep {
    display: flex;
    min-height: calc(100vh - 60px);  /* 减去导航栏高度 */
    margin-top: 60px;  /* 添加顶部间距 */
    padding: 30px;
    background-color: #fff; /* 将背景色改为白色 */
}

.student-connection-status {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.student-connection-content {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    width: 100%;
}

/* 左侧简介区域优化 */
.student-left-section {
    display: flex;
    flex-direction: column;
    width: 300px;
    max-height: 300px !important; /* 使用 max-height 并添加 !important */
    height: 300px !important;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* 添加垂直滚动条 */
}

.student-teacher-intro {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 100%;
}

.student-teacher-intro h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
    padding-bottom: 10px;
    border-bottom: 2px solid #eee;
}

.student-teacher-intro p {
    margin: 0;
    line-height: 1.6;
    color: #666;
    word-break: break-word;
}

/* 右侧视频和控制区域优化 */
.student-right-section {
    flex: 1;
    min-width: 0;
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.student-video-container {
    flex: 1;
    height: 400px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.student-video-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #1a1a1a;
}

/* 控制按钮区域优化 */
.student-controls-and-status {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
}

.student-controls-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.student-control-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.student-control-button {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    background: #f8f9fa;
    color: #495057;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.student-control-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.student-control-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.student-control-button.active {
    background-color: #4caf50;
    color: white;
}

.student-control-button.active:hover {
    background-color: #45a049;
}

.student-control-button.inactive {
    background-color: #f44336;
    color: white;
}

.student-control-button.inactive:hover {
    background-color: #e53935;
}

.student-start-button {
    width: 50px;
    height: 50px;
    background-color: #2196f3;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.student-start-button:hover {
    background-color: #1976d2;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(33, 150, 243, 0.3);
}

.student-start-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(33, 150, 243, 0.2);
}

/* 状态显示区域优化 */
.student-connection-status-container {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 12px;
    margin-top: 10px;
}

.student-connection-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 0;
}

/* 响应式设计优化 */
@media (max-width: 1024px) {
    .student-connection-content {
        flex-direction: column;
        align-items: center;
    }

    .student-left-section {
        flex: 0 0 100%;
        max-width: 600px;
    }

    .student-right-section {
        width: 100%;
        max-width: 600px;
    }
}

@media (max-width: 768px) {
    .student-connection-prep {
        min-height: 100vh;  /* 移动端恢复全屏高度 */
        margin-top: 0;  /* 移动端移除顶部间距 */
        padding: 0;
        background-color: white;
        display: flex;
        flex-direction: column;
    }

    .student-connection-status {
        padding: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .student-connection-content {
        flex-direction: column;
        gap: 0;
        flex: 1;
    }

    /* 视频区域 */
    .student-right-section {
        order: -1;
        width: 100%;
        gap: 0;
        flex-direction: column;
    }

    .student-video-container {
        border-radius: 0;
        box-shadow: none;
        height: 250px;
        background: #000;
    }

    /* 控制按钮区域 - 移到视频下方 */
    .student-controls-and-status {
        padding: 15px;
        order: 1;  /* 确保在视频之后 */
    }

    .student-controls-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 15px 0;
    }

    .student-control-buttons {
        flex-direction: row;
        gap: 20px;
        margin: 0;
    }

    /* 开始按钮和控制按钮并排 */
    .student-start-button {
        margin: 0;
    }

    /* 状态显示区域 */
    .student-connection-status-container {
        margin-top: 15px;
        padding: 15px;
        background: #f8f9fa;
        border-radius: 8px;
    }

    /* 教师简介区域 */
    .student-left-section {
        flex: none;
        width: 100%;
        margin-top: 15px;
    }

    .student-teacher-intro {
        border-radius: 0;
        box-shadow: none;
        height: auto;
        padding: 20px;
        background: white;
        border-top: 1px solid #eee;
    }

    .student-teacher-intro h2 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    /* 底部按钮区域 */
    .student-action-buttons {
        padding: 15px;
        margin: 0;
        background: white;
        border-top: 1px solid #eee;
    }
}

@media (max-width: 480px) {
    .student-video-container {
        height: 250px;
    }
}

/* 美化滚动条 */
.student-teacher-intro::-webkit-scrollbar {
    width: 6px;
}

.student-teacher-intro::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

.student-teacher-intro::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.student-teacher-intro::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.student-success-icon {
    color: #4caf50;
    font-size: 24px;
}

.student-error-icon {
    color: #f44336;
    font-size: 24px;
}

.student-loading-spinner {
    width: 24px;
    height: 24px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.student-action-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}

.student-action-buttons button {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    background-color: #2196f3;
    color: white;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.student-action-buttons button:hover {
    background-color: #1976d2;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(33, 150, 243, 0.3);
}

.student-action-buttons button:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(33, 150, 243, 0.2);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.student-teacher-intro h3 {
    text-align: center;
    margin-bottom: 15px;
}