.direction-container {
  background: white;
  padding: 32px;
  border-radius: 20px;
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.1);
  user-select: none;
  text-align: center;
  max-width: 420px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.direction-tip {
  margin-bottom: 28px;
  color: #374151;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.5;
}

/* 添加高亮方向的样式 */
.direction-highlight {
  display: inline-block;
  font-size: 32px;
  margin: 0 4px;
  vertical-align: middle;
  color: #2563eb;
  font-weight: bold;
}

.direction-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.middle-row {
  display: flex;
  gap: 80px;
}

.direction-button {
  width: 64px;
  height: 64px;
  border-radius: 16px;
  border: none;
  background: linear-gradient(145deg, #2563eb, #1d4ed8);
  color: white;
  font-size: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

.direction-button:hover {
  transform: translateY(-3px);
  background: linear-gradient(145deg, #1d4ed8, #1e40af);
  box-shadow: 0 8px 16px rgba(37, 99, 235, 0.25);
}

.direction-button:active,
.direction-button.active {
  transform: translateY(1px);
  background: linear-gradient(145deg, #1e40af, #1d4ed8);
  box-shadow: 0 2px 8px rgba(37, 99, 235, 0.15);
}

.slider-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: modalFadeIn 0.3s ease;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 480px) {
  .direction-container {
    width: 90%;
    padding: 24px;
    margin: 0 16px;
  }
  
  .middle-row {
    gap: 48px;
  }
  
  .direction-button {
    width: 56px;
    height: 56px;
    font-size: 24px;
  }
  
  .direction-tip {
    font-size: 16px;
    margin-bottom: 24px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .direction-container,
  .slider-modal {
    animation: none;
  }
  
  .direction-button {
    transition: none;
  }
} 