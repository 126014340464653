.footer {
  background-color: #fff;
  padding: 40px 0 20px;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.05);
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  padding: 0 20px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-section h3 {
  color: #1a1a1a;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.footer-section p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #666;
  text-decoration: none;
  transition: color 0.2s;
}

.footer-section ul li a:hover {
  color: #2196F3;
}

.footer-bottom {
  text-align: center;
  padding-top: 20px;
  margin-top: 40px;
  border-top: 1px solid #eee;
}

.footer-bottom p {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

@media (max-width: 768px) {
  .footer {
    padding: 30px 0 15px;
  }

  .footer-content {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 0 15px;
  }

  .footer-section {
    text-align: center;
  }
} 