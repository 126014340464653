.container {
  display: flex;
  min-height: 100vh;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}

.form-container {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: clamp(1.5rem, 4vw, 3rem);
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
}

/* 创建一个通用的内容宽度容器 */
.input-wrapper {
  width: 100%;
  max-width: 280px;  /* 统一设置所有内容的宽度 */
  display: flex;      /* 添加 flex 布局 */
  flex-direction: column;
  align-items: stretch;  /* 让子元素填充容器宽度 */
}

.title {
  font-size: clamp(20px, 4vw, 24px);
  font-weight: bold;
  margin-bottom: clamp(20px, 4vh, 30px);
  text-align: center;
  width: 100%;  /* 确保标题也占满宽度 */
}

.input {
  background-color: #f5f5f5;
  padding: clamp(12px, 2vh, 15px);
  border-radius: 8px;
  margin-bottom: clamp(12px, 2vh, 15px);
  font-size: clamp(14px, 2vw, 16px);
  border: none;
  outline: none;
  width: 100%;  /* 确保输入框填充整个宽度 */
  box-sizing: border-box;  /* 添加 box-sizing 确保padding不会影响总宽度 */
}

.captcha-container {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}

.captcha-input {
  flex: 1;
  margin-bottom: 0;
}

.captcha-image {
  cursor: pointer;
  height: 46px;
  min-width: 100px;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
}

.captcha-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.register-button {
  background-color: #22c55e;
  color: #fff;
  padding: clamp(12px, 2vh, 15px);
  border-radius: 8px;
  border: none;
  font-size: clamp(14px, 2vw, 16px);
  font-weight: 600;
  cursor: pointer;
  margin-top: clamp(5px, 1vh, 10px);
  width: 100%;  /* 填充父容器宽度 */
}

.register-button:hover {
  background-color: #1ea952;
}

.login-link {
  margin-top: clamp(15px, 3vh, 20px);
  color: #22c55e;
  font-size: clamp(14px, 2vw, 16px);
  background: none;
  border: none;
  cursor: pointer;
  padding: clamp(8px, 1.5vh, 10px);
  width: 100%;  /* 填充父容器宽度 */
  text-align: center;
}

.login-link:hover {
  text-decoration: underline;
}

.radio-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: clamp(10px, 2vh, 20px) 0;
  gap: clamp(15px, 3vw, 25px);
  width: 100%;  /* 填充父容器宽度 */
}

.radio-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: clamp(14px, 2vw, 16px);
  padding: 5px;
}

.radio-group input[type="radio"] {
  width: clamp(14px, 2vw, 16px);
  height: clamp(14px, 2vw, 16px);
  margin-right: 8px;
}

.name-inputs {
  display: flex;
  gap: clamp(8px, 2vw, 10px);
  width: 100%;
  margin-bottom: clamp(12px, 2vh, 15px);
}

.name-input {
  flex: 1;
}

/* 响应式设计 */
@media (min-width: 1024px) {
  .form-container {
    max-width: 480px;
    padding: 3rem;
  }
}

/* 平板断点 */
@media (max-width: 768px) {
  .form-container {
    max-width: 100%;
    padding: 2rem;
  }
}

/* 手机断点 */
@media (max-width: 480px) {
  .container {
    align-items: flex-start;
    padding-top: clamp(2rem, 5vh, 4rem);
  }

  .form-container {
    padding: clamp(1rem, 3vw, 1.5rem);
  }
}

/* 确保在较小屏幕上的最小可用性 */
@media (max-width: 320px) {
  .form-container {
    padding: 1rem;
  }
  
  .radio-group {
    flex-direction: column;
    align-items: flex-start;
  }
}

.slider-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.slider-container {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  user-select: none;
}

.slider-track {
  position: relative;
  width: 300px;
  height: 40px;
  background: #f0f0f0;
  border-radius: 20px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.slider-progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #22c55e;
  opacity: 0.2;
  transition: width 0.1s ease;
  border-radius: 20px;
}

.slider-button {
  position: absolute;
  width: 40px;
  height: 40px;
  background: linear-gradient(135deg, #22c55e 0%, #16a34a 100%);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  touch-action: none;
}

.slider-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.slider-button:active {
  transform: scale(0.95);
}

.slider-icon {
  font-style: normal;
  transition: transform 0.3s ease;
}

.slider-button:hover .slider-icon {
  transform: translateX(2px);
}

.slider-tip {
  text-align: center;
  color: #666;
  margin-top: 12px;
  font-size: 14px;
}

/* ���应式设计 */
@media (max-width: 480px) {
  .slider-container {
    width: 90%;
    padding: 15px;
  }

  .slider-track {
    width: 100%;
  }
}

/* 确保所有子元素都使用 border-box */
* {
  box-sizing: border-box;
} 

.register-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.register-title {
  margin: 0;  /* 移除标题默认的 margin */
}