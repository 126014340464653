.teacher-list {
  flex: 1;
  padding: 20px;
  margin-top: 60px;
}

.teacher-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.teacher-card {
  position: relative;
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teacher-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.avatar-container {
  width: 120px;
  height: 120px;
  margin-bottom: 16px;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.teacher-name {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
}

.online-indicator {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 12px;
  height: 12px;
  background-color: #22c55e;
  border-radius: 50%;
  border: 2px solid white;
}

@media (max-width: 1024px) {
  .teacher-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .teacher-grid {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 0 16px;
  }

  .teacher-card {
    padding: 16px;
  }

  .avatar-container {
    width: 100px;
    height: 100px;
  }
}

.loading {
  text-align: center;
  padding: 20px;
  color: #666;
} 