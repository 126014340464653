.change-password-container {
    padding: 80px 20px 20px;
    max-width: 500px;
    margin: 0 auto;
}

.change-password-card {
    background: white;
    border-radius: 16px;
    padding: 40px 40px 100px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    position: relative;
}

.change-password-card h2 {
    margin: 0 0 20px;
    color: #333;
    text-align: center;
}

.change-password-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-group label {
    font-weight: 500;
    color: #666;
    font-size: 0.9rem;
}

.form-group input {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
}

/* 固定在底部的按钮组样式 */
.change-password-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background: #f8f9fa;
    border-top: 1px solid #eee;
    border-radius: 0 0 16px 16px;
}

.button-group {
    display: flex;
    gap: 15px;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
}

.submit-button,
.cancel-button {
    flex: 1;
    min-width: 120px;
    max-width: 200px;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s;
    border: none;
    font-weight: 500;
}

.submit-button {
    background: #3498db;
    color: white;
}

.submit-button:hover {
    background: #2980b9;
    transform: translateY(-1px);
}

.cancel-button {
    background: #f5f5f5;
    color: #333;
}

.cancel-button:hover {
    background: #e0e0e0;
    transform: translateY(-1px);
}

.error-message {
    color: #f44336;
    margin-bottom: 20px;
    text-align: center;
}

/* 响应式设计 */
@media (max-width: 768px) {
    .change-password-container {
        padding: 70px 15px 15px;
    }

    .change-password-card {
        padding: 20px 20px 120px;
    }

    .button-group {
        flex-direction: column;
    }

    .submit-button,
    .cancel-button {
        max-width: none;
        width: 100%;
    }

    .change-password-footer {
        padding: 15px;
    }
}