/* 聊天区域 */
.room-chat {
    position: fixed;
    right: 0;
    top: calc(50% + 32px);
    width: 380px;
    height: calc(50vh - 32px);
    background: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    border-top-left-radius: 16px;
}

.room-chat--collapsed {
    transform: translateX(100%);
}

.room-chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

/* 聊天消息样式 */
.room-chat-message {
    margin: 4px 0;
    padding: 12px;
    border-radius: 12px;
    max-width: 85%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.room-chat-message--self {
    background-color: #DCF8C6;
    margin-left: auto;
    border-bottom-right-radius: 4px;
}

.room-chat-message--other {
    background-color: #E8E8E8;
    margin-right: auto;
    border-bottom-left-radius: 4px;
}

.room-chat-message-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 12px;
    color: #666;
}

/* 消息输入框 */
.room-chat-message-input {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    border-top: 1px solid #eee;
    background: white;
    display: flex;
    gap: 10px;
    height: 70px;
    box-sizing: border-box;
}

.chat-input-container {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 8px;
}

.chat-input-container input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
}

.room-chat-message-input button {
    padding: 8px 16px;
    background-color: #0084ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.room-chat-message-input button:hover {
    background-color: #0073e6;
}

/* 文件相关样式 */
.file-upload-label {
    cursor: pointer;
    padding: 5px;
}

.file-icon {
    color: #666;
    font-size: 1.2em;
}

.file-icon:hover {
    color: #333;
}

.file-message {
    display: flex;
    align-items: center;
    padding: 8px;
    background: #f5f5f5;
    border-radius: 4px;
    margin: 4px 0;
    max-width: 100%;
}

.file-message-icon {
    font-size: 24px;
    color: #666;
    min-width: 24px;
    margin-right: 10px;
}

.file-message-info {
    flex: 1;
    margin: 0;
    position: relative;
    min-width: 0;
}

.file-message-name {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.file-message-size {
    font-size: 12px;
    color: #666;
}

.file-download-btn {
    padding: 8px;
    color: #4CAF50;
    border-radius: 4px;
    transition: all 0.2s;
    min-width: 32px;
    margin-left: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 添加终止图标的样式 */
.file-download-btn svg[data-icon="stop"] {
    color: #ff4444;
}

.file-download-btn:hover {
    background: rgba(76, 175, 80, 0.1);
}

/* 当显示终止图标时的悬停效果 */
.file-download-btn:hover svg[data-icon="stop"] {
    background: rgba(255, 68, 68, 0.1);
}

.download-progress {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #f0f0f0;
    border-radius: 2px;
    margin-top: 4px;
}

.progress-bar {
    height: 100%;
    background-color: #4CAF50;
    border-radius: 2px;
    transition: width 0.3s ease;
}

.progress-text {
    position: absolute;
    right: -30px;
    top: -2px;
    font-size: 12px;
    color: #666;
}

.file-download-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* 移动端适配 */
@media (max-width: 767px) {
    .room-chat {
        width: 100%;
    }
}

/* iOS 安全区域适配 */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
    .room-chat {
        padding-bottom: env(safe-area-inset-bottom);
    }
}

.room-chat-user-name {
    font-weight: 500;
    color: #2b5278;
    margin-right: 8px;
}

.room-chat-timestamp {
    color: #8696a0;
    font-size: 11px;
}

.room-chat-message--self .room-chat-timestamp {
    color: #64b587;
}

.mobile-close-chat {
    padding: 4px 8px;
    font-size: 14px;
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
    color: #666;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.mobile-close-chat:hover {
    background-color: rgba(0, 0, 0, 0.05);
} 