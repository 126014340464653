.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 0 20px;
}

.action-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.create-room-btn, .join-room-btn {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.create-room-btn:hover, .join-room-btn:hover {
  background-color: #0073e6;
}

.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ddd;
  line-height: 0.1em;
  margin: 20px 0;
}

.divider span {
  background: #f5f5f5;
  padding: 0 10px;
  color: #666;
}

.join-room-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.room-id-input {
  padding: 12px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.room-id-input:focus {
  border-color: #0084ff;
  outline: none;
}

.error-message {
  color: #f44336;
  font-size: 14px;
  margin-top: -5px;
}

h1 {
  margin-bottom: 40px;
  color: #333;
}

/* 响应式设计 */
@media (max-width: 480px) {
  .home-container {
    padding: 0 15px;
  }

  .create-room-btn, .join-room-btn {
    padding: 12px 20px;
    font-size: 16px;
  }

  .room-id-input {
    padding: 10px;
    font-size: 14px;
  }
}

.button-group {
  position: absolute;
  top: 80px;
  left: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.action-btn {
  position: relative;
  padding: 20px;
  border: none;
  border-radius: 12px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  width: 120px;
  height: 120px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-btn:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
}

/* 添加移动端响应式样式 */
@media (max-width: 768px) {
  .button-group {
    position: static;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .action-btn {
    width: 100%;
    height: 80px;
    font-size: 14px;
  }
}

.action-btn.status-success {
  background-color: #28a745;
  transform: scale(1.02);
  box-shadow: 0 0 15px rgba(40, 167, 69, 0.4);
  border: 2px solid #28a745;
}

.action-btn.status-error {
  background-color: #dc3545;
  transform: scale(1.02);
  box-shadow: 0 0 15px rgba(220, 53, 69, 0.4);
  border: 2px solid #dc3545;
}

.action-btn.status-success:hover {
  background-color: #218838;
}

.action-btn.status-error:hover {
  background-color: #c82333;
}

.action-btn.status-testing {
  background-color: #ffc107;
  animation: pulse 1.5s infinite;
  transform: scale(1);
  box-shadow: 0 0 15px rgba(255, 193, 7, 0.5);
  border: 2px solid #ffc107;
}

.action-btn.status-testing:hover {
  background-color: #e0a800;
  transform: scale(1.12);
  box-shadow: 0 0 20px rgba(255, 193, 7, 0.6);
}

.action-btn.status-starting {
  background-color: #ffc107;
  animation: pulse 1.5s infinite;
  transform: scale(1);
  box-shadow: 0 0 15px rgba(255, 193, 7, 0.5);
  border: 2px solid #ffc107;
}

.action-btn.status-starting:hover {
  background-color: #e0a800;
  transform: scale(1.12);
  box-shadow: 0 0 20px rgba(255, 193, 7, 0.6);
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 193, 7, 0.5);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.15);
    box-shadow: 0 0 25px rgba(255, 193, 7, 0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 193, 7, 0.5);
  }
}

.appointment-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff4444;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
