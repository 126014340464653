.seller-home {
  padding: 20px;
  padding-top: 80px;
  max-width: 1200px;
  margin: 0 auto;
}

.seller-profile,
.seller-account {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.info-grid {
  display: grid;
  gap: 20px;
}

.info-grid p {
  margin: 10px 0;
}

h1 {
  margin-bottom: 24px;
}

h2 {
  margin-bottom: 16px;
  color: #333;
}

.info-row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.action-button {
  padding: 4px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.action-button:hover {
  background-color: #f5f5f5;
  border-color: #ccc;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  min-width: 300px;
}

.modal-body {
  margin-top: 16px;
}

.modal-body input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.action-button.primary {
  background-color: #1890ff;
  color: white;
  border-color: #1890ff;
}

.action-button.primary:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}
