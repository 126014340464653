.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  z-index: 50;
}

.dialog {
  background-color: white;
  border-radius: 12px;
  padding: 32px;
  width: 420px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  animation: fadeIn 0.2s ease-out;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
  color: #1f2937;
}

.input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 24px;
  transition: all 0.2s;
}

.input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 8px;
}

.cancelButton {
  padding: 10px 24px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  color: #374151;
  font-weight: 500;
  transition: background-color 0.2s;
}

.cancelButton:hover {
  background-color: #f9fafb;
}

.confirmButton {
  padding: 10px 24px;
  background-color: #3b82f6;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  transition: background-color 0.2s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.confirmButton:hover {
  background-color: #2563eb;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.codeInputGroup {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 20px;
}

.codeInput {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.codeInput:focus {
  outline: none;
  border-color: #007bff;
} 