.settle-teacher-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  margin-top: 64px;
}

.settle-teacher-table-container {
  margin-top: 1.5rem;
  overflow-x: auto;
  background:
    linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 100% 0,
    radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 100% 0;
  background-repeat: no-repeat;
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  background-attachment: local, local, scroll, scroll;
}

.settle-teacher-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.settle-teacher-table th,
.settle-teacher-table td {
  padding: 0.75rem 1rem;
  text-align: left;
  border-bottom: 1px solid #eaeaea;
  @media (max-width: 768px) {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}

.settle-teacher-table th {
  background-color: #f8f9fa;
  font-weight: 600;
}

.settle-teacher-table tr:hover {
  background-color: #f8f9fa;
}

.settle-teacher-status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  @media (max-width: 768px) {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
}

.settle-teacher-status-st01 {
  background-color: #fef3c7;
  color: #92400e;
}

.settle-teacher-status-st02 {
  background-color: #dcfce7;
  color: #166534;
}

.settle-teacher-status-st03 {
  background-color: #fee2e2;
  color: #991b1b;
}

@media (max-width: 768px) {
  .settle-teacher-table thead {
    display: none;
  }

  .settle-teacher-table tr {
    display: block;
    margin-bottom: 1rem;
    border: 1px solid #eaeaea;
    border-radius: 8px;
  }

  .settle-teacher-table td {
    display: flex;
    padding: 0.5rem;
    border: none;
  }

  .settle-teacher-table td::before {
    content: attr(data-label);
    font-weight: 600;
    width: 40%;
    margin-right: 1rem;
  }
}

.button-group {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}

.button-group .settle-button {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  min-width: 100px;
}

.settle-button {
  border-radius: 8px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.blue-button {
  background-color: #3e9fd3;
  color: white;
}

.blue-button:hover {
  background-color: #1d4d67;
}

.green-button {
  background-color: #10b981;
  color: white;
}

.green-button:hover {
  background-color: #059669;
}

@media (max-width: 768px) {
  .button-group {
    flex-direction: row;
    width: 100%;
  }
}