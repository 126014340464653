/* 基础布局 */
.room {
  --header-height: 64px;
  --control-bar-height: 80px;
  --video-max-width: 1280px;
  
  display: flex;
  height: calc(100vh - var(--header-height));
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: var(--header-height);
  justify-content: center;
  align-items: flex-start;
}

/* 视频区域 */
.room-video-section {
  width: 60%;
  max-width: var(--video-max-width);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px;
  position: relative;
  margin: 0 auto;
  transition: margin-right 0.3s ease;
}

/* 主视频容器 */
.room-main-video-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  overflow: hidden;
  object-fit: contain;
  background-color: #1a1a1a;
}

.room-main-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* object-fit: cover; */
  /* background-color: #000; */
}

/* 画中画视频 */
.room-pip-video-container {
  --pip-width: 160px;
  --pip-height: 240px;
  
  position: fixed;
  right: 75px;
  top: 65px;
  width: var(--pip-width);
  height: var(--pip-height);
  z-index: 10;
  cursor: move;
  transition: transform 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  touch-action: none;
}

.room-pip-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #000;
}

.room-pip-video-container:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

/* 拖动时的样式 */
.room-pip-video-container.dragging {
  opacity: 0.8;
  transform: scale(1.02);
  transition: none;
}

/* 控制栏 */
.room-controls {
  --control-btn-size: 50px;
  
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 24px;
  z-index: 1000;
  padding: 16px 32px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.room-control-btn {
  width: var(--control-btn-size);
  height: var(--control-btn-size);
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.room-control-btn svg {
  width: 20px;
  height: 20px;
}

.room-control-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
  border-color: rgba(255, 255, 255, 0.3);
}

.room-control-btn:active {
  transform: translateY(1px);
}

.room-control-btn.active {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.4);
}

.room-control-btn.inactive {
  background: rgba(255, 0, 0, 0.2);
  border-color: rgba(255, 0, 0, 0.4);
}

/* 屏幕共享模式 */
.object-fit-contain .room-main-video {
  object-fit: contain;
  background-color: #1a1a1a;
}

/* 响应式设计 */
@media (max-width: 767px) {
  .room-video-section {
    width: 90%;
    padding: 0 10px;
  }
  
  .room-pip-video-container {
    width: 90px;
    height: 135px;
    right: 20px;
    top: 80px;
  }
  
  .room-controls {
    bottom: 30px;
    width: 92%;
    max-width: 450px;
    padding: 12px 20px;
    gap: 12px;
  }
  
  .room-control-btn {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .room-video-section {
    width: 98%;
    padding: 0 8px;
  }
  
  .room-controls {
    bottom: 20px;
    padding: 10px 16px;
    gap: 8px;
  }
}

.screen-sharing-text {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    white-space: nowrap;
    user-select: none;
}

/* Replace the existing pen-button styles with these new styles */
.floating-controls {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 1000;
}

.floating-control-btn {
    width: 44px;
    height: 44px;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.floating-control-btn:hover {
    background: rgba(0, 0, 0, 0.8);
    transform: scale(1.05);
}

.floating-control-btn.active {
    background: rgba(0, 0, 0, 0.8);
    color: #007bff;
}

.floating-control-btn svg {
    width: 20px;
    height: 20px;
}

/* Add new styles for wide mode */
.room-video-section {
    transition: width 0.3s ease;
}

.room-video-section.wide-mode {
    width: 90%;
    max-width: none;
}

/* Update responsive styles */
@media (max-width: 767px) {
    .floating-controls {
        top: 10px;
        right: 10px;
        gap: 8px;
    }

    .floating-control-btn {
        width: 36px;
        height: 36px;
    }
}

.hidden {
  display: none;
}

/* 添加未读消息提示的样式 */
.room-control-btn.has-notification {
    position: relative;
}

.room-control-btn.has-notification::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: #ff4444;
    border-radius: 50%;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}