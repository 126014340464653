.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 2.5rem;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 50px;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.brand-text {
  font-size: 1.6rem;
  font-weight: 600;
  color: #1a1a1a;
  background: linear-gradient(45deg, #2196F3, #00BCD4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-menu {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.username {
  color: #666;
  font-size: 0.95rem;
  white-space: nowrap;
}

.nav-buttons {
  display: flex;
  gap: 1rem;
}

.nav-link {
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: #555;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.nav-link:hover {
  background-color: #f5f5f5;
  color: #2196F3;
}

.nav-link:last-child {
  background-color: #f0f7ff;
  color: #2196F3;
}

.nav-link:last-child:hover {
  background-color: #e1efff;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.user-profile:hover {
  background-color: #f5f5f5;
}

.navbar-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0.8rem 1.2rem;
    height: 56px;
  }
  
  .brand-text {
    font-size: 1.3rem;
  }
  
  .navbar-menu {
    gap: 0.8rem;
  }
  
  .username {
    font-size: 0.85rem;
    display: block;
  }
  
  .nav-buttons {
    gap: 0.5rem;
  }
  
  .nav-link {
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
  }
  
  .navbar-avatar {
    width: 28px;
    height: 28px;
  }
  
  .user-profile {
    gap: 0.5rem;
  }
} 