.appointment-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.timeline-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
}

.day-row {
    display: flex;
    align-items: center;
    padding: 4px 0;
}

.day-label {
    width: 80px;
    font-weight: 500;
    color: #666;
}

.time-block {
    height: 8px;
    margin: 2px 1px;
    width: 12px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.time-block:hover {
    transform: scaleY(1.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.time-block:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 10px;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.time-block-available {
    background-color: #52c41a;
    background-image: linear-gradient(to bottom, #95de64, #52c41a);
}

.time-block-booked {
    background-color: #f7b100;
    background-image: linear-gradient(to bottom, #f7b100, #f7b100);
}

.time-block-unavailable {
    background-color: #ffffff;
    cursor: not-allowed;
}
