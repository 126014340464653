.drawing-canvas-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 64px;
}

.drawing-canvas-wrapper canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: crosshair;
}

.drawing-controls {
    position: fixed;
    top: 84px;
    left: 20px;
    display: flex;
    gap: 10px;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 8px;
    z-index: 1000;
}

.drawing-controls input[type="color"] {
    width: 40px;
    height: 30px;
    padding: 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.drawing-controls input[type="range"] {
    width: 100px;
}

.drawing-controls button {
    padding: 5px 10px;
    background: #ff4444;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.drawing-controls button:hover {
    background: #ff6666;
}

.canvas-container {
  position: fixed;
  top: 64px;
  left: 50%;
  transform: translate(-50%, 0);
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1100;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 84px);
  user-select: none; /* 防止拖动时选中文本 */
}

.canvas-toolbar {
  display: flex;
  gap: 8px;
  padding: 12px;
  background: #f5f5f5;
  align-items: center;
  cursor: grab; /* 工具栏显示拖动光标 */
}

.canvas-tool-btn {
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.canvas-tool-btn:hover {
  background: #f0f0f0;
}

.canvas-tool-btn.active {
  background: #e6e6e6;
  border-color: #999;
}

.color-picker {
  width: 40px;
  height: 30px;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.canvas-area {
  position: relative;
  width: 800px;
  height: calc(100vh - 180px);
  background: white;
  overflow: hidden;
}

.canvas-area canvas {
  cursor: none; /* 隐藏默认光标 */
}

/* 为画笔和橡皮擦添加不同的光标样式 */
.canvas-area.pen canvas {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2'%3E%3Ccircle cx='12' cy='12' r='3'%3E%3C/circle%3E%3Ccircle cx='12' cy='12' r='1' fill='black'%3E%3C/circle%3E%3C/svg%3E") 12 12, auto;
}

.canvas-area.eraser canvas {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2'%3E%3Crect x='6' y='6' width='12' height='12' rx='2'%3E%3C/rect%3E%3C/svg%3E") 12 12, auto;
}

.text-input-container {
  position: absolute;
  display: flex;
  gap: 4px;
  padding: 4px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1200;
}

.text-input-container input {
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.text-input-container button {
  padding: 4px 8px;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.text-input-container button:hover {
  background: #e0e0e0;
}

.canvas-area.text canvas {
  cursor: text;
}

@media (max-width: 767px) {
  .canvas-container {
    transform: none; /* 移除默认的居中transform，使用position来定位 */
    width: 100vw;
    height: 100vh;
    top: 56px;
    left: 0;
    border-radius: 0;
    max-height: calc(100vh - 56px);
  }
  
  .canvas-area {
    width: 100%;
    height: calc(100vh - 120px); /* 减去顶部导航栏和工具栏的高度 */
  }

  .canvas-toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px;
    justify-content: center;
    background: #f5f5f5;
  }

  .canvas-tool-btn {
    padding: 6px 12px;
    font-size: 14px;
    min-width: auto;
  }

  .color-picker {
    width: 40px;
    height: 30px;
  }

  input[type="range"] {
    width: 80px;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
    touch-action: none; /* 防止移动端的默认触摸行为 */
  }
} 