@keyframes blink {
    50% {
        border-color: transparent;
    }
}

.toast-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.3);
}

.toast-content {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px 40px;
    border-radius: 8px;
    border: 2px solid red;
    animation: blink 1s step-end infinite;
    max-width: 80%;
    text-align: center;
    pointer-events: auto;
}