.profile-container {
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
}

.profile-card {
  background: white;
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.profile-card h2 {
  margin: 0 0 20px;
  color: #333;
  text-align: center;
}

.avatar-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.profile-avatar {
  width: 140px;
  height: 140px;
  border-radius: 70px;
  object-fit: cover;
  border: 4px solid #f0f0f0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-group,
.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.info-group {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.info-group label {
  font-weight: 600;
  color: #555;
  font-size: 0.95rem;
  margin-bottom: 0;
  min-width: 120px;
  flex-shrink: 0;
}

.info-group span {
  color: #2c3e50;
  font-size: 1.1rem;
  text-align: left;
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 100%;
  display: block;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-form input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.button-group {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.edit-button {
  flex: 1;
  min-width: 140px;
  max-width: 200px;
  background: #3498db;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s;
  text-align: center;
  white-space: nowrap;
  font-weight: 500;
  box-shadow: 0 2px 6px rgba(52, 152, 219, 0.2);
}

.edit-button:hover {
  background: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.3);
}

.cancel-button {
  flex: 1;
  min-width: 140px;
  max-width: 200px;
  background: #f5f5f5;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.cancel-button:hover {
  background: #e0e0e0;
}

.error-message {
  color: #f44336;
  margin-bottom: 20px;
  text-align: center;
}

.time-info {
  display: flex;
  align-items: center;
  gap: 15px;
  background: #fff;
  padding: 10px 15px;
  border-radius: 6px;
  border: 1px solid #eee;
  flex-wrap: wrap;
}

.recharge-button {
  padding: 8px 16px;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(39, 174, 96, 0.2);
}

.recharge-button:hover {
  background-color: #219a52;
  transform: translateY(-1px);
}

.avatar-upload-button {
  background-color: #4a90e2;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.avatar-upload-button:hover {
  background-color: #357abd;
}

.action-button {
  padding: 6px 16px;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(39, 174, 96, 0.2);
  margin-left: auto;
}

.action-button:hover {
  background-color: #219a52;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(39, 174, 96, 0.3);
}

.action-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(39, 174, 96, 0.2);
}

.action-button.edit {
  background-color: #3498db;
  box-shadow: 0 2px 4px rgba(52, 152, 219, 0.2);
}

.action-button.edit:hover {
  background-color: #2980b9;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.3);
}

@media (max-width: 768px) {
  .profile-container {
    padding: 70px 15px 15px;
  }

  .profile-card {
    padding: 20px;
  }

  .profile-avatar {
    width: 100px;
    height: 100px;
  }

  .button-group {
    flex-direction: column;
    align-items: stretch;
  }

  .edit-button {
    max-width: none;
    width: 100%;
  }

  .info-group {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .time-info {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .time-info span {
    text-align: center;
  }

  .recharge-button {
    width: 100%;
    padding: 12px 16px;
  }
}