.admin-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 80px 24px 24px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
}

.admin-title {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 32px;
    font-size: 28px;
}

.create-seller-card {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
}

.sell-code-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 16px;
    font-size: 16px;
}

.sell-code-input:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.create-button {
    width: 100%;
    padding: 12px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.create-button:hover {
    background-color: #2980b9;
}

.seller-card {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.seller-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.username-label {
    color: #666;
    font-weight: 500;
}

.username-value {
    color: #2c3e50;
    font-weight: 500;
}

.copy-button {
    margin-left: auto;
    padding: 8px 16px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
}

.copy-button:hover {
    background-color: #e9ecef;
}

/* 添加响应式设计 */
@media (max-width: 600px) {
    .admin-container {
        padding: 16px;
    }
    
    .create-seller-card,
    .seller-card {
        padding: 16px;
    }
}

/* 添加 ID 颜色样式 */
.room-id {
    color: #e74c3c;  /* 红色 */
    font-weight: 600;
}

.teacher-id {
    color: #2ecc71;  /* 绿色 */
    font-weight: 600;
}

.student-id {
    color: #3498db;  /* 蓝色 */
    font-weight: 600;
}

.session-id {
    color: #9b59b6;  /* 紫色 */
    font-size: 0.9em;
}
