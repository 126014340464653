.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    min-width: 300px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.modal-header h3 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
}

.modal-body {
    color: #333;
}

.buttons-container {
    display: flex;
    gap: 12px;
    margin: 20px 0;
}

.book-button {
    margin: 0;
    padding: 12px 24px;
    background-color: #52c41a;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
    font-weight: 500;
    width: 50%;
    box-shadow: 0 2px 6px rgba(82, 196, 26, 0.3);
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.book-button:hover {
    background-color: #389e0d;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(82, 196, 26, 0.4);
}

.time-range-button {
    margin: 0;
    padding: 12px 24px;
    background-color: #1890ff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    font-size: 16px;
    font-weight: 500;
    width: 50%;
    transition: all 0.3s ease;
    box-shadow: 0 2px 6px rgba(24, 144, 255, 0.3);
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.time-range-button:hover {
    background-color: #096dd9;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(24, 144, 255, 0.4);
}

.time-range-selector {
    margin: 16px 0;
    padding: 16px;
    background-color: #f8f9fa;
    border-radius: 4px;
}

.time-input-group {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.time-input-group label {
    min-width: 80px;
    color: #666;
}

.time-input-group select {
    padding: 6px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: white;
}

.time-input-group select:focus {
    outline: none;
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.timeline-container {
    margin: 20px 0;
}

.timeline-label {
    margin-bottom: 12px;
    color: #666;
    font-size: 16px;
}

.timeline-bar {
    width: 500px;
    height: 32px;
    background-color: #f0f0f0;
    border-radius: 6px;
    display: flex;
    overflow: hidden;
}

.timeline-minute {
    flex: 1;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    user-select: none;
    transition: opacity 0.2s;
}

.timeline-minute:last-child {
    border-right: none;
}

.timeline-minute.available {
    background-color: #95de64;
}

.timeline-minute.booked {
    background-color: #ff7875;
}

.timeline-minute:hover {
    opacity: 0.8;
}

.timeline-minute.selected {
    background-color: #1890ff;
    opacity: 0.8;
}

.selection-info {
    margin-top: 12px;
    font-size: 16px;
    color: #666;
    font-weight: 500;
}

.timeline-minute.booked {
    cursor: not-allowed;
} 